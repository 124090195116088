<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <!-- ############################### -->
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap justify-space-between>
        <v-flex xs12 sm6 md6 lg6>
          <span class="text-h4 font-weight-black">{{
            getEmployeeName(project.assignedTo)
          }}</span>
        </v-flex>
        <v-flex xs12 sm2 md2 lg2 class="text-right">
          <v-btn rounded color="success" v-if="project.status == 'done'" dark>
            {{ $t("done") }} <v-icon right>mdi-check</v-icon>
          </v-btn>
          <v-btn
            rounded
            color="red"
            v-else-if="project.status == 'overdue'"
            dark
          >
            {{ $t("overdue") }} <v-icon right>mdi-alarm-check</v-icon>
          </v-btn>
          <v-btn rounded :color="$store.state.secondaryColor" v-else dark>
            {{ $t("in progress") }} <v-icon right>mdi-progress-check</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify="center" align="center">
        <v-flex xs12 sm12 md12 lg12 class="">
          <v-list dense class="elevation-1">
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("project title") }} :
                </span>
                <span class="">
                  {{ project.projectTitle }}
                </span>
              </v-col>

              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("business") }} :
                </span>
                <span class="">
                  {{ getBusinessName(project.businessId) }}
                </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("due date") }}:
                </span>
                <span class="">
                  {{ formatDate(project.dueDate) }}
                </span>
              </v-col>
              <v-col cols="12" md="12">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("description") }}
                </span>
                <v-textarea
                  outlined
                  disabled
                  class="mt-3"
                  name="input-7-4"
                  :label="$t('description')"
                  :value="project.description"
                ></v-textarea>
                <span class=""> </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
    <!-- ################################### -->
    <!-- ########### -->
  </v-container>
</template>
<script>
import { format } from "date-fns";
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    dialog: false,
    expenseClaims: "",
    employeesList: [],
    pleaseWait: true,
  }),
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },
  },

  created() {
    this.getProject();
    this.getAllEmployees();
  },
  methods: {
    getProject() {
      this.project = "";
      db.collection("projects")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.project = doc.data();
          this.filteredproject = this.project;
        });
    },
    goBack() {
      window.history.back();
    },
    getAllEmployees() {
      this.employeesList = [];
      if (this.selected) {
        db.collection("employees")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employeesList.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.pleaseWait = false;
          });
      } else {
        db.collection("employees")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employeesList.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.pleaseWait = false;
          });
      }
    },
    getEmployeeName(id) {
      let name = this.employeesList.find((item) => item.employeeId == id);

      let employeeName = name.names.first + " " + name.names.last;
      return employeeName;
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },

    formatDate(val) {
      return format(val.toDate(), "EEE, dd MMMM, yyyy");
    },
  },
};
</script>
<style></style>
